import { DoctorReportPage } from '@component/doctor-report/doctor-report.page';
import { PrintLayout } from '@web/atomic/obj.print-layout/print-layout.styled';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { Col, Row } from '@web/atomic';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { PageProps } from '../../utils/local-types';

const RelatorioMedicoPage: React.FunctionComponent<PageProps> = (props) => {
  const [userId, setUserId] = useState<string>();
  useEffect(() => {
    const fetchUser = async () => {
      console.log('DIARY_DEBUG: relatorio-medico.tsx ~ line 18 ~ fetchUser ~ id');
      const id = await UserIdDataSource.getId();
      setUserId(id);
    };
    fetchUser();
  }, [setUserId]);

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Relatório para profissional de saúde',
          description: '',
        }}
      />

      {userId ? (
        <PrintLayout>
          <DoctorReportPage userId={userId} />
        </PrintLayout>
      ) : (
        <Row mt mb>
          <Col xs={12}>
            <TextShimmerBoxStyled height={'22px'} />
          </Col>
        </Row>
      )}
    </WebviewLayout>
  );
};

export default RelatorioMedicoPage;

export const query = graphql`
  query RelatorioMedicoQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
